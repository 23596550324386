<template>
  <div>
    <div class="inner job-posting pb0">
      <div class="tit req-type">
        <h1>계좌 번호</h1>
        <p class="stit">일자리 대금을 지급받을 계좌정보를 등록해주세요</p>
      </div>
      <div class="sub-cont">
        <div class="myp-form-wrap">
          <dl>
            <dt>등록계좌</dt>
            <dd>
              <div class="form-item-wrap">
                <div class="label">은행명</div>
                <select ref="bank" class="select" @change="changeBank($event)">
                  <option>은행을 선택하세요.</option>
                  <option
                    v-for="(bank, idx) in bankCodeList"
                    :key="`bt_${idx}`"
                    :value="bank.code"
                    :selected="bank.code == bankCode"
                  >
                    {{ bank.codeValue }}
                  </option>
                </select>
              </div>
              <div class="form-item-wrap">
                <div class="label">예금주</div>
                <input
                  type="text"
                  v-model="actHolder"
                  ref="actHolder"
                  class="inp"
                  value=""
                />
              </div>
              <div class="form-item-wrap">
                <div class="label">계좌번호</div>
                <input
                  type="text"
                  v-model="actNumber"
                  ref="actNumber"
                  class="inp"
                  value=""
                />
              </div>
              <div class="help-msg">‘-’를 제외하고 입력해주세요</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="myp-btn-wrap">
      <button type="button" class="btn-save01" @click="save">저장</button>
    </div>
  </div>
</template>

<script>
import { getBankCodeList, getAccountNum, saveAccountNum } from 'Api/modules'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      bankCode: '',
      bankName: '',
      actHolder: '',
      actNumber: '',
      bankCodeList: [],
    }
  },
  computed: {
    ...mapGetters(['account']),
  },
  methods: {
    changeBank(event) {
      this.bankCode = event.target.value
      this.bankName =
        event.target.options[event.target.options.selectedIndex].text
    },
    async findBankCodeList() {
      this.bankCodeList = await getBankCodeList()
    },
    async save() {
      // 정합성 체크
      if (!this.bankCode) {
        this.$toasted.error('은행을 선택하세요.')
        this.$refs['bank'].focus()
        return
      }

      if (!this.actHolder) {
        this.$toasted.error('예금주를 선택하세요.')
        this.$refs['actHolder'].focus()
        return
      }

      if (!this.actNumber) {
        this.$toasted.error('계좌번호를 선택하세요.')
        this.$refs['actNumber'].focus()
        return
      }

      if (!/^[0-9]*$/.test(this.actNumber)) {
        this.$toasted.error('숫자만 입력하세요.')
        this.$refs['actNumber'].focus()
        return
      }

      const saveData = {
        bankCode: this.bankCode,
        bankName: this.bankName,
        actHolder: this.actHolder,
        actNumber: this.actNumber,
      }

      //입력 오류
      // if (!this.validateInput(saveData)) return false

      try {
        await saveAccountNum(saveData)
        this.isSaved = true
        this.$toasted.show('저장되었습니다.')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
  async created() {
    // 은행코드 목록 조회
    await this.findBankCodeList()

    const res = await getAccountNum({ userId: this.account.userId })
    if (res) {
      this.bankCode = res.bankCode
      this.bankName = res.bankName
      this.actHolder = res.actHolder
      this.actNumber = res.actNumber
    }
  },
}
</script>

<style></style>
